import { CookieBanner } from './WCPCookieBanner.module.css';

export const COOKIE_BANNER_ID = 'cookie-banner';

export const isWCPCookieBannerEnabled = (): boolean => {
    let isEnabledInBrowser = false;

    try {
        isEnabledInBrowser = typeof window !== 'undefined';
    } catch (error) {
        console.error('Error accessing localStorage:', error);
    }

    return isEnabledInBrowser;
};

export const WCPCookieBanner = () => {
    const isEnabled = isWCPCookieBannerEnabled();

    if (isEnabled) {
        return <div id={COOKIE_BANNER_ID} className={CookieBanner}></div>;
    }
};
