import { captureException, getCurrentScope } from '@sentry/gatsby';
import { Component, type ErrorInfo, type PropsWithChildren } from 'react';

import { isSupportedBrowser } from '../../util/seo.js';

interface ErrorBoundaryProps {
    showRefresh?: boolean;
}

export class ErrorBoundary extends Component<
    PropsWithChildren<ErrorBoundaryProps>,
    { hasError: boolean }
> {
    constructor(props: PropsWithChildren<ErrorBoundaryProps>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    override componentDidCatch(error: Error, info: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.error(error);
        console.error(info);
        try {
            if (isSupportedBrowser()) {
                const scope = getCurrentScope();
                Object.keys(info).forEach(key => {
                    scope.setExtra(key, info[key as keyof typeof info]);
                });
                captureException(error);
            }
        } catch (e) {
            // As this is in the error boundary, be extra safe.
            console.error('Failed to log to sentry', e);
        }
    }

    override render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <h1>Something went wrong.</h1>
                    {this.props.showRefresh && <h2>Try refreshing your browser</h2>}
                    {!isSupportedBrowser() && (
                        <h3>
                            You're using an unsupported browser. Try updating to a modern browser.
                        </h3>
                    )}
                </>
            );
        }

        return this.props.children;
    }
}
