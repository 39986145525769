import { getSrc, type IGatsbyImageData } from 'gatsby-plugin-image';
import platform from 'platform';

import type { SEOImageProps } from '../components/meta/Seo.js';
import type { FilterContentType } from '../shared/types/index.js';

export function imagePropsFromImage(
    image: IGatsbyImageData | undefined
): SEOImageProps | undefined {
    if (!image) {
        return undefined;
    }
    return {
        url: getSrc(image) || '',
        height: image.height,
        width: image.width,
    };
}

export function getTypeSeoName(type: FilterContentType, removeStock = false): string {
    switch (type) {
        case 'all':
            return 'content';
        case 'audio':
            return removeStock ? 'audio' : 'stock audio';
        case 'video':
            return removeStock ? 'footage' : 'stock footage';
        case 'image':
            return removeStock ? 'image' : 'stock image';
        case 'template':
            return 'video template';
        case 'motion-title':
            return 'motion title';
        case 'graphic-element':
            return 'graphic element';
        case 'filter':
            return 'filter';
        case 'transition':
            return 'transition';
        case 'element':
            return 'element';
    }
}

export function getTypeSeoNamePlural(type: FilterContentType): string {
    switch (type) {
        case 'all':
            return 'content';
        case 'audio':
            return 'stock audio';
        case 'video':
            return 'stock footage';
        case 'image':
            return 'stock images';
        case 'template':
            return 'video templates';
        case 'motion-title':
            return 'motion titles';
        case 'graphic-element':
            return 'graphic elements';
        case 'filter':
            return 'filters';
        case 'transition':
            return 'transitions';
        case 'element':
            return 'elements';
    }
}

export function getTypeSeoNameShortPlural(type: FilterContentType): string {
    switch (type) {
        case 'all':
            return 'all';
        case 'audio':
            return 'audio';
        case 'video':
            return 'video';
        case 'image':
            return 'images';
        case 'template':
            return 'templates';
        default:
            return getTypeSeoNamePlural(type);
    }
}

const POSSIBLE_TITLES = ['royalty free', 'high quality', 'licensed'];

export function formatTitleForSEO(contentId: string): string {
    /*
    We want to choose three random words instead of the first three, but we want this
    random selection to stay the same across deploys, so we generate the random number from a seed
  */
    const seed = xmur3(contentId);
    const randF = mulberry32(seed());
    const rand = randF() * 1000;

    return POSSIBLE_TITLES[Math.ceil(rand) % POSSIBLE_TITLES.length];
}

export function formatKeywordsForSEO(contentId: string, keywords: string[]): string {
    /*
    We want to choose three random words instead of the first three, but we want this
    random selection to stay the same across deploys, so we generate the random number from a seed
  */
    const seed = xmur3(contentId);
    const randF = mulberry32(seed());
    const rand = randF() * 1000;

    const tempWords = [...keywords];
    let newWords: string[] = [];

    if (keywords.length <= 2) {
        return tempWords.join(', ');
    } else {
        for (let i = 0; i < 3; i++) {
            const n = Math.ceil(rand) % tempWords.length;
            newWords = [...newWords, ...tempWords.splice(n, 1)];
        }
        return `${newWords[0]}, ${newWords[1]}, and ${newWords[2]}`;
    }
}

export function isSupportedBrowser(): boolean {
    return (
        platform.name !== 'IE' &&
        (((platform.name === 'Chrome' || platform.name === 'Edge') &&
            Number.parseInt(platform.version || '0') > 80) ||
            (platform.name === 'Firefox' && Number.parseInt(platform.version || '0') > 72))
    );
}

// Some seeded random helpers for keywords
function xmur3(str: string) {
    let h = 1779033703 ^ str.length;
    for (let i = 0; i < str.length; i++) {
        h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    }
    h = (h << 13) | (h >>> 19);
    return function () {
        h = Math.imul(h ^ (h >>> 16), 2246822507);
        h = Math.imul(h ^ (h >>> 13), 3266489909);
        return (h ^= h >>> 16) >>> 0;
    };
}

function mulberry32(a: number) {
    return function () {
        let t = (a += 0x6d2b79f5);
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
}
