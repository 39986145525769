import cookie from 'cookie';

const COOKIES_KEY = 'acceptCookies'; // to match app
const GRANULAR_CONSENT_COOKIE_KEY = 'cookieConsent';
const isBrowser = typeof window !== 'undefined';

export const setGranularConsentCookie = ({
    analytics,
    advertising,
    social,
}: {
    analytics: boolean;
    advertising: boolean;
    social: boolean;
}) => {
    if (isBrowser) {
        const domain = location.hostname.includes('clipchamp.com')
            ? '.clipchamp.com'
            : location.hostname;

        const date = new Date();
        date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
        const cookieString = cookie.serialize(
            GRANULAR_CONSENT_COOKIE_KEY,
            JSON.stringify({ analytics, advertising, social }),
            {
                domain: domain,
                expires: date,
                path: '/',
            }
        );
        document.cookie = cookieString;
    }
};

export const setCookie = (value: boolean) => {
    if (isBrowser) {
        const domain = location.hostname.includes('clipchamp.com')
            ? '.clipchamp.com'
            : location.hostname;

        const date = new Date();
        date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
        const cookieString = cookie.serialize(COOKIES_KEY, JSON.stringify(value), {
            domain: domain,
            expires: date,
            path: '/',
        });
        document.cookie = cookieString;
    }
};

export const removeCookie = () => {
    if (isBrowser) {
        const storedCookie = cookie.parse(document.cookie)[COOKIES_KEY];
        if (storedCookie) {
            document.cookie = cookie.serialize(COOKIES_KEY, '', {
                expires: new Date(0),
                path: '/',
            });
        }
    }
};
