/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import { AnalyticsProvider } from './src/components/analytics.js';
import { ErrorBoundary } from './src/components/ui/ErrorBoundary.js';
import { CookieContextProvider } from './src/components/useCookies.js';
import { WCPCookieBanner } from './src/components/WCPCookieBanner.js';

export const onRenderBody = ({
    setHtmlAttributes,
    loadPageDataSync,
    pathname,
    setHeadComponents,
}) => {
    if (process.env.NODE_ENV !== 'development') {
        const {
            result: {
                pageContext: { lang },
            },
        } = loadPageDataSync(pathname);
        // set the default language;
        let htmlLang = 'en';
        if (lang) {
            // use only two-letter language code
            htmlLang = lang.split('-').shift();
        }
        setHtmlAttributes({ lang: htmlLang });
    }
    setHeadComponents([
        // eslint-disable-next-line react/jsx-key
        <script src="https://wcpstatic.microsoft.com/mscc/lib/v2/wcp-consent.js"></script>,
    ]);
};

export const wrapRootElement = ({ element }) => {
    return (
        <ErrorBoundary>
            <WCPCookieBanner />
            <CookieContextProvider locale={element?.props?.pageContext?.lang ?? 'en'}>
                <AnalyticsProvider>{element}</AnalyticsProvider>
            </CookieContextProvider>
        </ErrorBoundary>
    );
};
