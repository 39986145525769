/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { logPageView } from './src/components/analytics.js';
export { wrapRootElement } from './gatsby-ssr.js';

export const onRouteUpdate = () => {
    logPageView();
};
